// Variable overrides
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities"; 

@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');


$body-bg: #ffffff;
$body-color: #292525;
$primary:#1b1717;



$font-family-sans-serif: 'Montserrat', sans-serif;



