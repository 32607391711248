$primary_color_theme: #b01310;

$scondary_color_theme: #383737;

$third_color_theme: #fff7f7;

$fourth_color_theme: #fcfafa;

$fived_color_theme: #b01310;

$fonthead: 'Rubik', sans-serif;
$fontstack: 'Rubik', sans-serif;
$colordark: #353353;
$coloryellow: #FFD15C;
$colorpink: #FF4C60;
$colorblue: #6C6CE5;
$colordefault: #454360;


//colors

$primary_color : #ff7171;
$primary_color_2 : #d4243a;


$primary_color_2_darker : #9a3743;
$second_color: #ffe8e8;
$third_color : #7e7e7e;
$second_color_lighter : #fef6f6;
$secondary_color_darker: #ffb3b3;
$border_color: #d4243a;
$text_color: #d4243a;
$modal-shadow: rgba(0, 0, 0, 0.2); 

$yellow_color : #efbb1a;
$yellow_color_lighter : #f5d675;
$yellow_color_darker :  #bf9514;

$gray-color: #7e7e7e;
$gray-color-lighter: #a6a6a6;
$gray-color-darker: #5a5a5a;



body {
  color: $scondary_color_theme;
}
.table {
  color: $scondary_color_theme;
}

.minidropdown {
  padding: 3px 20px;
}

.superminidropdown {
  padding: 0.5px 20px;
}
.card-header {
  background-color: #ffffff00 !important;
  color: #000000 !important;
}


.list-group-item_produits {
  background-color: #e2e2e2 !important;
  color: #000000 !important;
}
.dropdown-item i {
  color: #060606;
}

.nav-tabs .nav-link {
  font-weight: bold;
}

.btn-success {
  background-color: $fived_color_theme;
  border-color: $fived_color_theme;
}
.btn-success:hover {
  background-color: #de3835;
  border-color: #de3835;
}

.sidebar {
  background-color: $third_color_theme !important;
}

.sidebar .nav-link {
  color: $scondary_color_theme;
}

.page-item.active .page-link {
  background-color: $scondary_color_theme;
  border-color: $scondary_color_theme;
}

.page-link:hover {
  background-color: $fived_color_theme;
  border-color: $fived_color_theme;
  color: #ffffff;
}

.sidebar .nav-dropdown.open .nav-link {
  color: $fourth_color_theme;
}

.sidebar .nav-link.active {
  color: #ffffff;
  background: $fived_color_theme;
}
.sidebar .nav-link:hover {
  color: #ffffff;
  background: $fived_color_theme;
}

.card-header {
  background-color: $third_color_theme;
  color: $primary_color_theme;
}

.sidebar .nav-link.active .nav-icon {
  color: $fived_color_theme;
}

@media (min-width: 992px) {
  .sidebar-minimized .sidebar .sidebar-minimizer {
    background-color: $fourth_color_theme !important;
    color: #b3b3b3;
  }
}

.card-columns.cols-1 {
  column-count: 1;
}

.homeChart .chart-wrapper {
  height: 25em !important;
  color: "red";
}

.DateRangePickerInput__block {
  text-align: center;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
}

.sidebar {
  .sidebar-minimizer {
    background-color: $fourth_color_theme !important;
    opacity: 0.9;
  }
  .sidebar-minimizer::before {
    color: #ffffff !important;
  }

  .nav-link.active .nav-icon {
    color: #ffffff;
  }
}

.table-no-border-top {
  thead th,
  thead td {
    border-top: none !important;
  }
}

.container_checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  :hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: #f33a21;
  }
  /* When the checkbox is checked, add a blue background */
  .checkmark_first {
    top: -8px;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 10px;
    top: 6px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.catalogue_tables {
  ::-webkit-input-placeholder {
    /* Edge */
    text-transform: uppercase;
    font-style: italic;
    font-weight: 400;
  }

  .table th,
  .table td {
    vertical-align: middle;
    font-weight: 500;
  }

  .table thead th,
  .table thead td {
    text-transform: uppercase;
  }
  .cell_thumbnail {
    color: transparent;
    display: inline-block;
    width: 40px;
    height: 40px;
    margin: auto;
    border-radius: 2px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 5px;
  }
  .input-group-text {
    border: none;
    background: #ffffff;
  }

  .form-control {
    border: none;
  }
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;

  input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    text-transform: capitalize;
  }

  .btn {
    border: 1px solid gray;
    color: #ffffff;
    background-color: $scondary_color_theme;
    padding: 32px;
    border-radius: 8px;
    cursor: pointer;
    text-transform: capitalize;
  }
}

/* class applies to select element itself, not a wrapper element */
.select-css {
  display: block;
  font-weight: normal;
  color: $fived_color_theme;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  //box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  //border-radius: .5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #ffffff 0%, #f2faff 100%);
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 0.65em auto, 100%;

  /* Hover style */
  :hover {
    border-color: #888;
  }

  /* Focus style */
  :focus {
    border-color: #aaa;
    /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
    box-shadow: 0 0 1px 3px $fived_color_theme;
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: rgb(255, 255, 255);
    outline: none;
  }
}

/* Set options to normal weight */
.select-css option {
  font-weight: normal;
}

.success_costum,
.success_costum:active {
  color: #ffffff !important;
  background-color: $scondary_color_theme !important;
  border-color: $scondary_color_theme !important;
  height: 30px;
  width: 30px;
}

.success_costum_with_out_color,
.success_costum_with_out_color:active {
  color: $scondary_color_theme !important;
  background-color: transparent !important;
  border-color: $scondary_color_theme !important;
  height: 30px;
  width: 30px;
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff !important;
  background-color: #f86c6b !important;
  border-color: #f86c6b !important;
}

input.apple-switch {
  position: relative;
  appearance: none;
  outline: none;
  width: 50px;
  height: 30px;
  background-color: #ffffff;
  border: 1px solid #d9dadc;
  border-radius: 50px;
  box-shadow: inset -20px 0 0 0 #ffffff;
  transition-duration: 200ms;
}

input.apple-switch:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 26px;
  height: 26px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
}

input.apple-switch:checked {
  border-color: $scondary_color_theme;
  box-shadow: inset 20px 0 0 0 $scondary_color_theme;
}

input.apple-switch:checked:after {
  left: 20px;
  box-shadow: -2px 4px 3px rgba(255, 255, 255, 0.979);
}

label.label_with_height_line {
  line-height: 30px;
}

.fade-appear {
  opacity: 0;
  z-index: 3;
}
.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 1000ms linear;
}

.btn-primary {
  background-color: $scondary_color_theme !important;
  border-color: $scondary_color_theme !important;
}
.list-group-item.active {
  background-color: $scondary_color_theme;
  border-color: $scondary_color_theme;
}

.bg-primary,
.card.bg-primary {
  background-color: $scondary_color_theme !important;
  border-color: $scondary_color_theme !important;
}

.image_profil {
  text-indent: 2px;
  height: 35px;
  width: 35px;
  display: inline-block;
  border: 1px solid $scondary_color_theme;
  line-height: 33px;
}

.w-5 {
  width: 40px !important;
}
.h-5 {
  height: 40px !important;
}

span.image-row {
  display: inline-block;
  text-align: center;
  line-height: 30px;
}

.react-datepicker-popper,
.DateRangePicker_picker {
  z-index: 9999 !important;
}

.react-datepicker-popper,
.DateRangePicker_picker_1 {
  left: 12% !important;
}

// .react-datepicker-popper .DateRangePicker_picker__directionLeft {
//   //left: 20% !important;
// }

// .sidebar .nav-dropdown.open {
//    background: #404040!important;

// }

.sidebar .nav-dropdown.open {
  background: #f7eaea;
}
.sidebar .nav-dropdown.open .nav-link {
  color: #000000;
}
.sidebar .nav-dropdown.open .nav-link:hover {
  color: #ffffff;
}
.nav-link .nav-dropdown-toggle {
  background: #ffffff;
}
.sidebar .nav-link.active {
  color: #ffffff !important;
  background: #b01310;
}

.btn-success1 {
  background-color: rgb(2, 170, 2);
  border-color: rgb(3, 153, 3);
  color: white;
}

.btn-success1:hover {
  background-color: rgb(57, 104, 57);
  border-color: rgb(82, 138, 82);
  color: white;
}

.btn-success2 {
  background-color: rgb(194, 194, 194);
  border-color: rgb(190, 190, 190);
  color: white;
}
.btn-success2:hover {
  background-color: rgb(170, 170, 170);
  border-color: rgb(159, 160, 159);
  color: white;
}
.dx-c-bs4-container {
  height: 300px !important;
}
.sizemodalpr {
  max-width: 570px !important;
}

.card-header_2 {
  background-color: #cfcfcfa6 !important;
  color: #000000 !important;
}

.checkbox_rg {
  position: absolute !important;
  margin-top: 0.3rem !important;
  margin-left: -0.25rem !important;
}
.checkbox_rg_2 {
  position: absolute !important;
  margin-top: -0.7rem !important;
  margin-left: -0.25rem !important;
}

.padding-right_ {
  padding-right: 1px !important;
}
.padding-left_ {
  padding-left: 1px !important;
}

.bo-mb_0 {
  margin-bottom: 0px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;

  p {
    margin-bottom: 0rem !important;
  }
}
//////////////////////////////TREEE//////////////////

.tree {
    /*overflow-x: hidden;
    overflow-y: auto;*/
    background-color: rgb(232, 62, 116);
    font-size: 40px;
  }
  
  .m-node.placeholder {
    border: 1px dashed #1385e5;
  }
  
  .m-node .inner {
    color: #d8e0f0;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .m-node .node {
    display: inline-block;
    width: 100%;
    padding: 4px 5px;
  }
  .collapse:not(.show) {
    display: block;
}
  
  /*
  .m-node .node:before {
    content: "I";
    width: 20px;
    height: 20px;
    border: 1px solid red;
  }
  */
  
  .m-node .node.is-active {
    background-color: lightblue;
  }
  .react-contextmenu {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    color: #373a3c;
    font-size: 16px;
    margin: 2px 0 0;
    min-width: 160px;
    outline: none;
    opacity: 0;
    padding: 5px 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;
  }
  
  .react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
  }
  
  .react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: #373a3c;
  cursor: pointer;
    font-weight: 400;
    line-height: 1.5;
    padding: 3px 20px;
    text-align: inherit;
    white-space: nowrap;
  }
  
  .react-contextmenu-item.react-contextmenu-item--active,
  .react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
  }
  
  .react-contextmenu-item.react-contextmenu-item--disabled,
  .react-contextmenu-item.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
    color: #878a8c;
  }
  
  .react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0,0,0,.15);
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;
  }
  .react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
  }
  
  .react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
  }
  
  .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
  }
  
  .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
    content: "▶";
    display: inline-block;
    position: absolute;
    right: 7px;
  }
  
  .example-multiple-targets::after {
    content: attr(data-count);
    display: block;
  }

  .tree_checkbox {
    font-size: 20px;
  }

  .collapse{
    font-size: 24px;
  }

  .switch-wrapper > input[type="checkbox"] {
    opacity: 0;
    position: absolute;
  }
  
  .switch-wrapper > input[type="checkbox"] + .switch {
    transform: translateX(5px);
  }
  .switch-wrapper > input[type="checkbox"]:checked + .switch {
    transform: translateX(50%) translateX(-19px);
  }
  .switch-wrapper.large > input[type="checkbox"]:checked + .switch {
    transform: translateX(50%) translateX(-35px);
  }
  .switch-wrapper > input[type="checkbox"]:disabled + .switch {
    background: #ccc;
  }
  
  .switch-wrapper {
    border-radius: 20px;
    cursor: pointer;
    height: 24px;
    float: left;
    overflow: hidden;
    position: relative;
    width: 48px;
  }
  .switch-wrapper.large {
    height: 40px;
    width: 80px;
  }
  .switch-wrapper > .switch {
    color: #fff;
    display: inline-block;
    height: 100%;
    left: -100%;
    position: relative;
    transition: 100ms linear;
    width: 200%;
  }
  .switch-wrapper > .switch > .switch-handle {
    background: #fff;
    border-radius: 50%;
    display: inline-block;
    height: 14px;
    left: 50%;
    position: relative;
    top: 4px;
    width: 14px;
    z-index: 3;
  }
  .switch-wrapper.large > .switch > .switch-handle {
    height: 30px;
    width: 30px;
  }
  .switch-label {
    font-weight: 600;
    margin-left: 10px;
  }
  .switch-wrapper.large + .switch-label {
    line-height: 4rem;
    margin-left: 15px;
  }
  .label-chackBox {
    margin-top: 10px;
  }

  .btn-custom, .btn-custom:hover, .btn-custom:active, .btn-custom:visited {
    background-color: #009900 !important;
}

.marquee {
  --gap: 1rem;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  // margin-top: 5px;
  width: 70%;

  height: 50px;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 2500px) {
  .marquee {
    width: 70%;
  }
}

@media screen and (min-width: 901px) and (max-width: 1200px) {
  .marquee {
    width: 80%;
  }
}



@media screen and (max-width: 1345px) {
  .marquee {
    width: 61%;

  }
}

@media screen and (max-width: 900px) {
  .marquee {
    display:none;
  }
}



.marquee__content {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: var(--gap);
  max-width:fit-content;
  list-style-type: none;
}

@keyframes scroll {
  from {

    transform: translateX(250%);
  }
  to {
    transform: translateX(calc(-200% - var(--gap)));

    //transform: translate(-100%%)
  }
}

.marquee__content {

  animation: scroll 20s linear infinite;
}
.marquee:hover .marquee__content {
  animation-play-state: paused;
}



.shape {
  text-align: center;
  background:rgba(227,242,253,0.7);
  background-size: 30px 100%, 100% 100%;
  min-width: 500px;
  //line-height: 60px;
  padding: 8px;
  color: 	rgba(83,109,254,1.0); 
  border : solid 1px rgba(83,109,254,1.0);
  // margin: 20px auto;
  border-radius: 10px;
  position: relative;
  margin-top: 9px;
  margin-right: 8px;
}

@keyframes scroll-abs {
  from {
    transform: translateX(calc(100% + var(--gap)));
  }
  to {
    transform: translateX(0);
  }
}


///////////////////// 
.warning-card {
  // height: 80vh;
  background: rgba(244,202,22,0.2);
  width: 100%;
  &-button{ 
    right: 0;
    bottom:  0;
    margin-top: 20px;
  }
  &-img{
    width: 30px;
    height: 30px;
    padding-right: 5px;
    padding-bottom: 5px;
  }
  &-body {
    padding: 15px 25px 15px 25px ;
    background: rgba(244,202,22,0.8);
    border-radius: 10px;
  }
}



@media screen and (max-width: 425px) {
  .displayPointVente {
    display: none;
  }
}

.spanTitle {
  color: #383737;
  font-weight: 600;
}


  
  .listProduct-title {
      // margin-right: 140px;
      width: 30%;
      font-weight: 600;
    }

    .listProduct-prix{
      // margin-right: 140px;
    
      font-weight: 600;
      
      text-decoration: line-through;
      color: #b01310;
      float: right;
    }

    .listProduct-newPrix {
     
      color:#00b200;
      font-weight: 600;
      align-items: center;
    }

    .list-product {
      display: flex;
      
    }

    .Table__itemCount {
      /* margin-top: 10px; */
      font-size: 14px;
    }
    
    .Table__pagination {
      display: flex;
      justify-content: flex-end;
      padding: 20px 10px;
    }
    
    .Table__pageButton {
      font-size: 15px;
      outline: none;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
    
    .Table__pageButton:disabled {
      cursor: not-allowed;
      color: gray;
    }
    
    .Table__pageButton--active {
      color: #45b3e3;
      font-weight: bold;
    }
    
    .facture_tr {
      animation: blink_tr 3s 0s ;
    }
    
    @keyframes blink_tr {
      0% { background-color: #F5D3D3; }
      50% { background-color: white; }
      100% { background-color: #F5D3D3; }
    }

    .facture_tr_payed {
      animation: blink_green 3s 0s ;
    }
    
    @keyframes blink_green {
      0% { background-color: #84E1C6; }
      50% { background-color: white; }
      100% { background-color: #84E1C6; } }
    .collapse {
      &:not(.show) {
        display: none;
      }}

      @mixin transition($properties) {
        -webkit-transition: $properties;
        -moz-transition: $properties;
        -o-transition: $properties;
        transition: $properties;
      }
    

    $transition-collapse:         height .35s ease;
    $transition-collapse-width:   width .35s ease;
    
    .collapsing {
      height: 0;
      overflow: hidden;
      @include transition($transition-collapse);
    
      &.collapse-horizontal {
        width: 0;
        height: auto;
        @include transition($transition-collapse-width);
      }
    }


    /* Container for the entire datepicker */
.react-datepicker-wrapper {
  display: inline-block;
}

/* Input field for the datepicker */
.react-datepicker__input-container input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  outline: none;
}

/* Calendar popup */
.react-datepicker-popper {
  z-index: 1;
}

/* Calendar header */
.react-datepicker__header {
  background-color: #f0f0f0;
  border-radius: 4px 4px 0 0;
  padding: 10px;
  text-align: center;
  font-weight: bold;
}

/* Navigation buttons in the calendar header */
.react-datepicker__navigation {
  top: 16px;
}

/* Month and year dropdowns */
.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
  background-color: #f0f0f0;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 8px;
  font-size: 16px;
  outline: none;
}

/* Days of the week */
.react-datepicker__day-name {
  font-weight: bold;
}

/* Individual day cells */
.react-datepicker__day {
  padding: 8px;
  border-radius: 4px;
}

/* Today's date */
.react-datepicker__day--today {
  background-color: #e6f7ff;
}

/* Selected date */
.react-datepicker__day--selected {
  background-color: #1890ff;
  color: #fff;
}

.product-store-style {
  position: absolute;
  inset: 0 0 auto 0;
  bottom: 0;
  height: 36px;
  background-image: linear-gradient(to top, #000000 50%, transparent 100%);
  opacity: 0.5;
}

.product-image-style {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.product-group:hover .group-hover\:opacity-75 {
  opacity: 0.75;
}

.btn-addToCard {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  background-color: #4a5568;
  color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #718096;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5),
      0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
}


.images_store {
  height: 15em;
  background-size: cover; 
  background-position: center center; 
  background-repeat: no-repeat;
  border-radius: 2% 2% 0 0;
  transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
}
//fixed navbar css 

.onsale {
  z-index: 6;
  position: absolute;
  top: 15px;
  left: -20px;
  padding: 2px 10px;
  background: var(--red);
  color: #fff;
  box-shadow: -1px 2px 3px rgba(0, 0, 0, .3);
  border-radius: 0 5px 5px 0;
  height: 25px;
  line-height: 25px;
  font-size: 0.8rem;
  font-weight: normal;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 0;

  &:before, &:after {
    content: "";
    position: absolute;
  }

  &:before {
    width: 7px;
    height: 33px;
    top: 0;
    left: -6.5px;
    padding: 0 0 7px;
    background: inherit;
    border-radius: 5px 0 0 5px;
  }

  &:after {
    width: 5px;
    height: 5px;
    bottom: -5px;
    left: -4.5px;
    border-radius: 5px 0 0 5px;
    background: #800;
  }
}


.price {
  font-size: 14px;

  ins {
    text-decoration: none;
    font-weight: 700;
    white-space: nowrap;
  }

  del {
    color: #666;
    font-size: 11px;
    padding-right: 7px;
    white-space: nowrap;
  }

  .sale-tag {
    color: red;
    font-size: 12px;
    padding-left: 7px;
    font-weight: 700;
  }

}

///////////////////////////@at-root


.floatingBottomStore {
  bottom: 8vh !important;
  right: 1vh !important;
}

.floatingBottomStoreOrginal {
  bottom: 16vh !important;
  right: 1vh !important;



}


.selectTypePrint {

  border : solid 2px blue !important;
}



.reclamationbutton {
 
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 14px;
  padding-bottom: 14px;
  animation-duration: .8s;
  animation-name: clignoter;
  animation-iteration-count: infinite;
  transition: none;
  background: "red"
}

.free_tag {
  animation-duration: .8s;
  
  animation-name: clignoter;
  animation-iteration-count: infinite;
  transition: none;
  background: "red"

}

@keyframes clignoter {
 0%   { background:#B20000; }
 40%   {background:#D06666; }
 100% { background:#B20000; }
}

.shrink-table_satisfcation td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


wrapper {
  width: 600px;
  margin: 0 auto;
  }
  
  .accordion-wrapper + * {
  margin-top: 0.5em;
  }
  
  .accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
  }
  
  .accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
  }
  
  .accordion-title {
  font-weight: 600;
  cursor: pointer;
  color: #666;
  padding: 0.5em 1.5em;
  border: solid 1px #ccc;
  border-radius: 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  }
  
  .accordion-title::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid currentColor;
  }
  
  .accordion-title:hover,
  .accordion-title.open {
  color: black;
  }
  
  .accordion-title.open::after {
  content: "";
  border-top: 0;
  border-bottom: 5px solid;
  }
  
  .accordion-item.collapsed .accordion-title::after {
  border-top: none;
  }
  
  .accordion-content {
  padding: 1em 1.5em;
  }


  @keyframes slide-in {
    from {
      transform: translateX(100%); /* Slide in from the right */
    }
    to {
      transform: translateX(0); /* End position, no translation */
    }
  }
  
  @keyframes slide-out {
    from {
      transform: translateX(0); /* Start position, no translation */
    }
    to {
      transform: translateX(100%); /* Slide out to the right */
    }
  }


.icon-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  color: #333333;
  background: #dddddd;
  border: none;
  outline: none;
  border-radius: 50%;
}

.icon-button:hover {
  cursor: pointer;
}

.icon-button:active {
  background: #cccccc;
}

.icon-button__badge {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 25px;
  height: 25px;
  background: red;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

projcard-container,
.projcard-container * {
	box-sizing: border-box;
}
.projcard-container {
	margin-left: auto;
	margin-right: auto;
	width: 1000px;
}
.projcard {
	position: relative;
	width: 100%;
	height: 300px;
	margin-bottom: 40px;
	border-radius: 10px;
	background-color: #fff;
	border: 2px solid #ddd;
	font-size: 18px;
	overflow: hidden;
	cursor: pointer;
	box-shadow: 0 4px 21px -12px rgba(0, 0, 0, .66);
	transition: box-shadow 0.2s ease, transform 0.2s ease;
}
.projcard:hover {
	box-shadow: 0 34px 32px -33px rgba(0, 0, 0, .18);
	transform: translate(0px, -3px);
}
.projcard::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-image: linear-gradient(-70deg, #424242, transparent 50%);
	opacity: 0.07;
}
.projcard:nth-child(2n)::before {
	background-image: linear-gradient(-250deg, #424242, transparent 50%);
}
.projcard-innerbox {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
.projcard-img {
	position: absolute;
	height: 300px;
	width: 400px;
	top: 0;
	left: 0;
	transition: transform 0.2s ease;
}
.projcard:nth-child(2n) .projcard-img {
	left: initial;
	right: 0;
}
.projcard:hover .projcard-img {
	transform: scale(1.05) rotate(1deg);
}
.projcard:hover .projcard-bar {
	width: 70px;
}
.projcard-textbox {
	position: absolute;
	top: 7%;
	bottom: 7%;
	left: 430px;
	width: calc(100% - 470px);
	font-size: 17px;
}
.projcard:nth-child(2n) .projcard-textbox {
	left: initial;
	right: 430px;
}
.projcard-textbox::before,
.projcard-textbox::after {
	content: "";
	position: absolute;
	display: block;
	background: #ff0000bb;
	background: #fff;
	top: -20%;
	left: -55px;
	height: 140%;
	width: 60px;
	transform: rotate(8deg);
}
.projcard:nth-child(2n) .projcard-textbox::before {
	display: none;
}
.projcard-textbox::after {
	display: none;
	left: initial;
	right: -55px;
}
.projcard:nth-child(2n) .projcard-textbox::after {
	display: block;
}
.projcard-textbox * {
	position: relative;
}
.projcard-title {
	font-family: 'Voces', 'Open Sans', arial, sans-serif;
	font-size: 24px;
}
.projcard-subtitle {
	font-family: 'Voces', 'Open Sans', arial, sans-serif;
	color: #888;
}
.projcard-bar {
	left: -2px;
	width: 50px;
	height: 5px;
	margin: 10px 0;
	border-radius: 5px;
	background-color: #424242;
	transition: width 0.2s ease;
}
.projcard-blue .projcard-bar { background-color: #0088FF; }
.projcard-blue::before { background-image: linear-gradient(-70deg, #0088FF, transparent 50%); }
.projcard-blue:nth-child(2n)::before { background-image: linear-gradient(-250deg, #0088FF, transparent 50%); }
.projcard-red .projcard-bar { background-color: #D62F1F; }
.projcard-red::before { background-image: linear-gradient(-70deg, #D62F1F, transparent 50%); }
.projcard-red:nth-child(2n)::before { background-image: linear-gradient(-250deg, #D62F1F, transparent 50%); }
.projcard-green .projcard-bar { background-color: #40BD00; }
.projcard-green::before { background-image: linear-gradient(-70deg, #40BD00, transparent 50%); }
.projcard-green:nth-child(2n)::before { background-image: linear-gradient(-250deg, #40BD00, transparent 50%); }
.projcard-yellow .projcard-bar { background-color: #F5AF41; }
.projcard-yellow::before { background-image: linear-gradient(-70deg, #F5AF41, transparent 50%); }
.projcard-yellow:nth-child(2n)::before { background-image: linear-gradient(-250deg, #F5AF41, transparent 50%); }
.projcard-orange .projcard-bar { background-color: #FF5722; }
.projcard-orange::before { background-image: linear-gradient(-70deg, #FF5722, transparent 50%); }
.projcard-orange:nth-child(2n)::before { background-image: linear-gradient(-250deg, #FF5722, transparent 50%); }
.projcard-brown .projcard-bar { background-color: #C49863; }
.projcard-brown::before { background-image: linear-gradient(-70deg, #C49863, transparent 50%); }
.projcard-brown:nth-child(2n)::before { background-image: linear-gradient(-250deg, #C49863, transparent 50%); }
.projcard-grey .projcard-bar { background-color: #424242; }
.projcard-grey::before { background-image: linear-gradient(-70deg, #424242, transparent 50%); }
.projcard-grey:nth-child(2n)::before { background-image: linear-gradient(-250deg, #424242, transparent 50%); }
.projcard-customcolor .projcard-bar { background-color: var(--projcard-color); }
.projcard-customcolor::before { background-image: linear-gradient(-70deg, var(--projcard-color), transparent 50%); }
.projcard-customcolor:nth-child(2n)::before { background-image: linear-gradient(-250deg, var(--projcard-color), transparent 50%); }
.projcard-description {
	z-index: 10;
	font-size: 15px;
	color: #424242;
	height: 125px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.projcard-tagbox {
	position: absolute;
	bottom: 3%;
	font-size: 14px;
	cursor: default;
	user-select: none;
	pointer-events: none;
}
.projcard-tag {
	display: inline-block;
	background: #E0E0E0;
	color: #777;
	border-radius: 3px 0 0 3px;
	line-height: 26px;
	padding: 0 10px 0 23px;
	position: relative;
	margin-right: 20px;
	cursor: default;
	user-select: none;
	transition: color 0.2s;
}
.projcard-tag::before {
	content: '';
	position: absolute;
	background: #fff;
	border-radius: 10px;
	box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
	height: 6px;
	left: 10px;
	width: 6px;
	top: 10px;
}
.projcard-tag::after {
	content: '';
	position: absolute;
	border-bottom: 13px solid transparent;
	border-left: 10px solid #E0E0E0;
	border-top: 13px solid transparent;
	right: -10px;
	top: 0;
}


.ccicon {
  height: 38px;
  position: absolute;
  right: 6px;
  top: calc(50% - 17px);
  width: 60px;
}

/* CREDIT CARD IMAGE STYLING */
.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}


#ccsingle {
  position: absolute;
  right: 15px;
  top: 20px;
}

#ccsingle svg {
  width: 100px;
  max-height: 60px;
}

.creditcard svg#cardfront,
.creditcard svg#cardback {
  width: 100%;
  -webkit-box-shadow: 1px 5px 6px 0px black;
  box-shadow: 1px 5px 6px 0px black;
  border-radius: 22px;
}

#generatecard{
  cursor: pointer;
  float: right;
  font-size: 12px;
  color: #fff;
  padding: 2px 4px;
  background-color: #909090;
  border-radius: 4px;
  cursor: pointer;
  float:right;
}

/* CHANGEABLE CARD ELEMENTS */
.creditcard .lightcolor,
.creditcard .darkcolor {
  -webkit-transition: fill .5s;
  transition: fill .5s;
}

.creditcard .lightblue {
  fill: #03A9F4;
}

.creditcard .lightbluedark {
  fill: #0288D1;
}

.creditcard .red {
  fill: #ef5350;
}

.creditcard .reddark {
  fill: #d32f2f;
}

.creditcard .purple {
  fill: #ab47bc;
}

.creditcard .purpledark {
  fill: #7b1fa2;
}

.creditcard .cyan {
  fill: #26c6da;
}

.creditcard .cyandark {
  fill: #0097a7;
}

.creditcard .green {
  fill: #66bb6a;
}

.creditcard .greendark {
  fill: #388e3c;
}

.creditcard .lime {
  fill: #d4e157;
}

.creditcard .limedark {
  fill: #afb42b;
}

.creditcard .yellow {
  fill: #ffeb3b;
}

.creditcard .yellowdark {
  fill: #f9a825;
}

.creditcard .orange {
  fill: #ff9800;
}

.creditcard .orangedark {
  fill: #ef6c00;
}

.creditcard .grey {
  fill: #bdbdbd;
}

.creditcard .greydark {
  fill: #616161;
}

/* FRONT OF CARD */
#svgname {
  text-transform: uppercase;
}

#cardfront .st2 {
  fill: #FFFFFF;
}

#cardfront .st3 {
  font-family: 'Source Code Pro', monospace;
  font-weight: 600;
}

#cardfront .st4 {
  font-size: 54.7817px;
}

#cardfront .st5 {
  font-family: 'Source Code Pro', monospace;
  font-weight: 400;
}

#cardfront .st6 {
  font-size: 33.1112px;
}

#cardfront .st7 {
  opacity: 0.6;
  fill: #FFFFFF;
}

#cardfront .st8 {
  font-size: 24px;
}

#cardfront .st9 {
  font-size: 36.5498px;
}

#cardfront .st10 {
  font-family: 'Source Code Pro', monospace;
  font-weight: 300;
}

#cardfront .st11 {
  font-size: 16.1716px;
}

#cardfront .st12 {
  fill: #4C4C4C;
}

/* BACK OF CARD */
#cardback .st0 {
  fill: none;
  stroke: #0F0F0F;
  stroke-miterlimit: 10;
}

#cardback .st2 {
  fill: #111111;
}

#cardback .st3 {
  fill: #F2F2F2;
}

#cardback .st4 {
  fill: #D8D2DB;
}

#cardback .st5 {
  fill: #C4C4C4;
}

#cardback .st6 {
  font-family: 'Source Code Pro', monospace;
  font-weight: 400;
}

#cardback .st7 {
  font-size: 27px;
}

#cardback .st8 {
  opacity: 0.6;
}

#cardback .st9 {
  fill: #FFFFFF;
}

#cardback .st10 {
  font-size: 24px;
}

#cardback .st11 {
  fill: #EAEAEA;
}

#cardback .st12 {
  font-family: 'Rock Salt', cursive;
}

#cardback .st13 {
  font-size: 37.769px;
}

/* FLIP ANIMATION */
.container {
  perspective: 1000px;
}

.creditcard {
  width: 100%;
  max-width: 400px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: -webkit-transform 0.6s;
  -webkit-transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  cursor: pointer;
}

.creditcard .front,
.creditcard .back {
  position: absolute;
  width: 100%;
  max-width: 400px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  color: #47525d;
}

.creditcard .back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.creditcard.flipped {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

@keyframes blink_ {
  0% {
    box-shadow: 0 0 6px 0px green;
  }
  50% {
    box-shadow: 0 0 6px 3px green;
  }
  100% {
    box-shadow: 0 0 6px 0px green;
  }
}

.blinking-button {
  height: 17px;
  width: 17px;
  background: green;
  border-radius: 50%;
  animation: blink_ 1s infinite;
  box-shadow: 0 0 6px 0px green;
}


@keyframes blink_Des {
  0% {
    box-shadow: 0 0 6px 0px rgb(176, 19, 16);
  }
  50% {
    box-shadow: 0 0 6px 3px rgb(176, 19, 16);
  }
  100% {
    box-shadow: 0 0 6px 0px rgb(176, 19, 16);
  }
}

.blinking-button_des {
  height: 17px;
  width: 17px;
  background: rgb(176, 19, 16);
  border-radius: 50%;
  animation: blink_Des 1s infinite;
  box-shadow: 0 0 6px 0px rgb(176, 19, 16);
}


.simple_loading {
  height: 0;
  width: 0;
  padding: 15px;
  border: 6px solid lightblue;
  border-right-color: blue;
  border-radius: 22px;
  -webkit-animation: rotate_ 1s infinite linear;
  /* left, top and position just for the demo! */

 
}

@-webkit-keyframes rotate_ {
  /* 100% keyframe for  clockwise. 
     use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}


@keyframes blinker_ {
  
  50% {
    opacity: 0;
  }
}

.blinking-badge {
  background: #B20000;
  margin-right: 5px;
  color: white;
  animation: blinker_ 1s linear infinite;
}

.blinking-badge_ {
  background: green;
  margin-right: 5px;
  color: white;
  animation: blinker_ 1s linear infinite;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.custom-tooltip {
  position: absolute;
  bottom: 100%;
  left: 20%;
  transform: translateX(-20%);
  z-index: 1;
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 13px;
  font-weight: 600;
}


.loader-wrapper {
  display: flex;
  align-items: center;
}

.dot {
  animation: growDots 1.5s infinite ease-in-out;
  display: inline-block;
  margin-left: 4px;
}

@keyframes growDots {
  0%, 20%, 100% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.5);
  }
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.5s;
}

.dot:nth-child(3) {
  animation-delay: 1s;
}

/////////////////////////
.portfolio-wrapper {
  width: 70%;
  height: 70%;
  [class*="col-"] {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.pf-filter-wrapper {
  display: none;
}

.portfolio-item {
  position: relative;
  overflow: hidden;

  .thumb {
    overflow: hidden;
  }

  .details {
    color: #fff;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;

    h4 {
      color: #fff;
      font-size: 20px;
      margin: 0 0 10px;
      padding: 0 20px;
      opacity: 0;
      transform: translateY(30px);
      transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
      position: absolute;
      top: 60px;
    }

    span.term {
      color: #fff;
      background: $colorpink;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      font-size: 14px;
      opacity: 0;
      display: inline-block;
      padding: 3px 10px;
      position: absolute;
      top: 0;
      left: 20px;
      transform: translateY(-40px);
      transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
    }

    .more-button {
      color: #fff;
      font-size: 20px;
      display: block;
      background: $coloryellow;
      border-radius: 100%;
      height: 40px;
      line-height: 42px;
      text-align: center;
      position: absolute;
      bottom: 20px;
      left: 20px;
      width: 40px;
      opacity: 0;
      @include transition(0.3s);
    }
  }

  .mask {
    background: $colorblue;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    @include transition(0.3s);
  }

  &:hover {
    .mask {
      opacity: 0.9;
    }

    .details {
      h4,
      span {
        opacity: 1;
        transform: translateY(0);
      }

      .more-button {
        opacity: 1;
      }
    }
  }
}

.portfolio-filter {
  margin-bottom: 25px;

  li {
    button {
      color: #5e5c7f;
      cursor: pointer;
      font-family: $fonthead;
      font-size: 16px;
      font-weight: 700;
      position: relative;
      @include transition(0.3s);
      height: auto;
      padding: 0;
      border: 0;
      outline: 0;
      background: transparent;

      &:hover,
      &.current {
        color: $colorpink;
      }
    }
  }

  li:not(:last-child) {
    margin-right: 1.8rem;
  }
}

select.portfolio-filter-mobile {
  font-size: 16px;
  border-radius: 10px;
  outline: 0;
  margin-bottom: 30px;
  position: relative;
  width: 100%;
}

.pf-filter-wrapper {
  position: relative;

  &:after {
    content: "\f0d7";
    font-family: "FontAwesome";
    position: absolute;
    top: 0;
    right: 20px;
    color: #000;
  }
}

.menu_item_global {
  cursor: pointer;
  transition: transform 0.5s, background 0.5s;

}

.menu_item_global:hover {
  
 
  transform: scale(1.05);
}


.service {
  text-align: center;
  padding: 25px 10px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  background: transparent;
  transition: transform 0.5s, background 0.5s;
}

.service i {
  font-size: 40px;
  margin-bottom: 10px;
  color: #303ef7;
}

.service h2 {
  font-weight: 600;
  margin-bottom: 8px;
}

.service:hover {
  background: $primary-color;
  color: #fff;
  transform: scale(1.05);
}

.service:hover i {
  color: #fff;
}

.service .DE_depot:hover {


    color : white


}

.row__ {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 30px;
}
.container__ {
  width: 100%;
  height: 100%;
  padding: 0px 8%;
}

.container__ h1 {
  text-align: center;
  padding-top: 2%;
  margin-bottom: 20px;
  font-weight: 600;
  position: relative;
}

.container__ h1::after {
  content: '';
  background: #303ef7;
  width: 100px;
  height: 5px;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}


.item_monCompte {
  background: #fff;
  text-align: center;
  padding: 30px 25px;
  -webkit-box-shadow:0 0px 25px rgba(0, 0, 0, 0.07);
  box-shadow:0 0px 25px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  border:5px solid rgba(0, 0, 0, 0.07);
  margin-bottom: 30px;
  -webkit-transition: all .5s ease 0;
  transition: all .5s ease 0;
  transition: all 0.5s ease 0s;
}
.item_monCompte:hover{
  background:#f91942;
  box-shadow:0 8px 20px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: all .5s ease 0;
  transition: all .5s ease 0;
  transition: all 0.5s ease 0s;
}
.item_monCompte:hover .item_monCompte, .item_monCompte:hover span.icon{
  background:#fff;
  border-radius:10px;
  -webkit-transition: all .5s ease 0;
  transition: all .5s ease 0;
  transition: all 0.5s ease 0s;
}
.item_monCompte:hover h6, .item_monCompte:hover p{
  color:#fff;
  -webkit-transition: all .5s ease 0;
  transition: all .5s ease 0;
  transition: all 0.5s ease 0s;
}
.item_monCompte .icon {
  font-size: 40px;
  padding : 10px;
  margin-bottom:25px;
  color: #f91942;   
  width: 90px;
  height: 90px;
  line-height: 96px;
  border-radius: 50px;
}
.item_monCompte .feature_box_col_one{
  background:rgba(247, 198, 5, 0.20);
  color:#f91942
}
.item_monCompte .feature_box_col_two{
  background:rgba(255, 77, 28, 0.15);
  color:#f91942
}
.item_monCompte .feature_box_col_three{
  background:rgba(0, 147, 38, 0.15);
  color:#f91942
}
.item_monCompte .feature_box_col_four{
  background:rgba(0, 108, 255, 0.15);
  color:#f91942
}
.item_monCompte .feature_box_col_five{
  background:rgba(146, 39, 255, 0.15);
  color:#f91942
}
.item_monCompte .feature_box_col_six{
  background:rgba(23, 39, 246, 0.15);
  color:#f91942
}
.item_monCompte p{
  font-size:15px;
  line-height:26px;
}
.item_monCompte h6 {
  margin-bottom:20px;
  color:#2f2f2f;
}



.container_Profile {
  color: white;
  max-width: 500px;
  border: 1px solid #333;
  margin: 0 auto;
  padding: 20px;
}

.card__Profile  {
  // background-color: white;
  // border: 1px solid #333;

  //background-image: url(https://files.123freevectors.com/wp-content/solidbackground/light-red-free-solidcolor-background.jpg);




  // text-align: center;
  // padding: 30px 25px;
  // -webkit-box-shadow:0 0px 25px rgba(0, 0, 0, 0.07);
  // box-shadow:0 0px 25px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  // border:5px solid rgba(0, 0, 0, 0.07);
  border: 1px solid #333;
  margin-bottom: 30px;
  -webkit-transition: all .5s ease 0;
  transition: all .5s ease 0;
  transition: all 0.5s ease 0s;


  border-radius: 5px;
  padding: 20px;
  text-align: center;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-info {
  margin-bottom: 20px;
  color:#333;
  font-weight: 600;
}

.name__Profile  {
  font-size: 24px;
  margin: 0;
}

.username__Profile  {
  font-size: 18px;
  margin: 0;
}

.stats__Profile  {
  color:#333;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.stat__Profile  {
  flex-grow: 1;
}

.number__Profile  {
  font-size: 24px;
  margin: 0;
}

.label__Profile  {
  font-size: 14px;
  margin: 0;
}

.appie-single-service-2 {
  border : 1px solid gray;
  padding: 34px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  background: transparent;
  // border: 1px solid transparent; /* Ensures border size consistency */
  box-shadow: none; /* Initial box shadow */
  transform: translateY(0); /* Start with no vertical translation */
  transition: transform 0.5s ease, background 0.5s, box-shadow 0.5s ease, border-top 0.5s ease; /* Include all transitions */
}
.appie-single-service-2:hover {
  transform: scale(1.1) translateY(-10px);
    border: 1px solid transparent;  
  border-top: 4px solid #ff3e66;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
@media (max-width: 767px) {
  .appie-single-service-2 {
    text-align: center;
    padding: 20px;
  }
}
.appie-single-service-2 .icon i {
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  background: #2eacfc;
  color: #fff;
  border-radius: 50%;
}
.appie-single-service-2 .title {
  font-size: 20px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 10px;
}
.appie-single-service-2 .link {
  font-size: 14px;
  font-weight: 500;
  color: #505056;
  margin-top: 30px;
}
.appie-single-service-2 .link:hover {
  color: #ff3e66;
}

.appie-fun-fact-box {
  margin-top: 20PX;
  background-color: $primary_color;
  // background-image: url(https://img.freepik.com/premium-photo/red-velvet-fabric-texture-used-as-background-empty-red-fabric-background-soft-smooth-textile-material-there-is-space-text_661047-4442.jpg);
  padding: 90px 70px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .appie-fun-fact-box {
    padding: 30px;
  }
}
.appie-fun-fact-box .appie-fun-fact-content > .title {
  font-size: 44px;
  line-height: 54px;
  font-weight: 600;
  color: #fff;
  padding-right: 80px;
  padding-bottom: 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-fun-fact-box .appie-fun-fact-content > .title {
    font-size: 40px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .appie-fun-fact-box .appie-fun-fact-content > .title {
    font-size: 28px;
    line-height: 38px;
    padding-right: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-fun-fact-box .appie-fun-fact-content > .title {
    font-size: 36px;
    line-height: 46px;
    padding-right: 0;
  }
}
.appie-fun-fact-box .appie-fun-fact-content p {
  color: #fff;
  opacity: 0.8;
  // font-size: 25px;
  font-weight: 500;
}
.appie-fun-fact-box .appie-fun-fact-content .appie-fun-fact-item {
  margin-top: 36px;
}
@media (max-width: 767px) {
  .appie-fun-fact-box .appie-fun-fact-content .appie-fun-fact-item {
    text-align: center;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-fun-fact-box .appie-fun-fact-content .appie-fun-fact-item {
    text-align: left;
  }
}
.appie-fun-fact-box .appie-fun-fact-content .appie-fun-fact-item .title {
  font-size: 30px;
  color: #fff;
}
.appie-fun-fact-box .appie-fun-fact-content .appie-fun-fact-item span {
  font-size: 14px;
  color: #a4a4b9;
}
.appie-fun-fact-box .appie-fun-fact-play {
  position: relative;
  z-index: 10;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-fun-fact-box .appie-fun-fact-play {
    margin-top: 50px;
  }
}
@media (max-width: 767px) {
  .appie-fun-fact-box .appie-fun-fact-play {
    margin-top: 50px;
    margin-bottom: 20px;
    text-align: center;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-fun-fact-box .appie-fun-fact-play {
    text-align: left;
  }
}
.appie-fun-fact-box .appie-fun-fact-play img {
  position: absolute;
  right: -40px;
  top: 0;
  z-index: 2;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-fun-fact-box .appie-fun-fact-play img {
    position: static;
  }
}
@media (max-width: 767px) {
  .appie-fun-fact-box .appie-fun-fact-play img {
    display: none;
  }
}
.appie-fun-fact-box .appie-fun-fact-play a {
  height: 70px;
  width: 70px;
  text-align: center;
  line-height: 70px;
  background: #fff;
  border-radius: 50%;
  color: #ff6b58;
  position: relative;
  z-index: 5;
}
.appie-fun-fact-box .appie-fun-fact-play a::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #fff;
  border-radius: 50%;
  animation: popup 1.5s linear infinite;
}



.background_qrcode_1 {
  background-color: $primary_color;
  // background-image: url(https://img.freepik.com/premium-photo/red-velvet-fabric-texture-used-as-background-empty-red-fabric-background-soft-smooth-textile-material-there-is-space-text_661047-4442.jpg);
}

.background_qrcode_2 {
  background-image: url(https://t3.ftcdn.net/jpg/04/12/82/16/360_F_412821610_95RpjzPXCE2LiWGVShIUCGJSktkJQh6P.jpg);
}


.box_main {
  width: auto ;
  height: 500px;
  background: #fff;
  margin-top:50px;
  margin-left: 100px;
  margin-right: 100px;
  border-radius:5px;
  box-shadow: 6px 18px 18px rgba(0, 0, 0, 0.08), -6px 18px 18px rgba(0, 0, 0, 0.08);
}

.animation_main{
  margin-top:20%;
  display:inline-block;
  margin-bottom:5%;
}

.animation_main h1 {
  font-size:32px;
  font-weight:400;
  text-transform:uppercase;
  margin:0;
}
.animation_main p{
  font-size:16px;
  font-weight:700;
  margin:0;
}

.animation_main a{
  color: #f6921e;
  font-weight: bold;
  text-decoration: none;
  margin-left:5px;
}

.one_main, .two_main, .three_main {
  display:block;
  float:left;
}

.one_main {
  background: url('data:image/svg+xml,%3Csvg%20version%3D%221.1%22%0A%09%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20xmlns%3Aa%3D%22http%3A%2F%2Fns.adobe.com%2FAdobeSVGViewerExtensions%2F3.0%2F%22%0A%09%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2281px%22%20height%3D%2280.5px%22%20viewBox%3D%220%200%2081%2080.5%22%20style%3D%22overflow%3Ascroll%3Benable-background%3Anew%200%200%2081%2080.5%3B%22%0A%09%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cstyle%20type%3D%22text%2Fcss%22%3E%0A%09.st0%7Bfill%3A%23383838%3B%7D%0A%3C%2Fstyle%3E%0A%3Cdefs%3E%0A%3C%2Fdefs%3E%0A%3Cpath%20class%3D%22st0%22%20d%3D%22M30.3%2C68.2c1.2%2C0.2%2C2.3%2C0.9%2C3.8%2C1.2c1.6%2C0.3%2C2.7%2C0.6%2C4%2C0.4l4.9%2C9.6c0.6%2C0.9%2C1.4%2C1.1%2C2.3%2C0.9l15.3-4.9%0A%09c0.5-0.3%2C1-1%2C0.9-2.3l-1.8-10.6c2-1.6%2C3.6-3.7%2C5.3-5.8l10.5%2C0.6c1.1%2C0.6%2C2.1-0.4%2C2.3-1.1L81%2C40.7c0.2-0.8-0.4-2.1-1.1-2.3l-10.2-3.8%0A%09c-0.3-2.5-1.4-4.8-2.5-7.5l5.9-8.5c0.6-1.1%2C0.4-1.9-0.2-2.9l-12-10.7c-0.3-0.5-1.6-0.3-2.5%2C0.3l-8%2C6.9c-1.2-0.2-2.3-0.9-3.8-1.2%0A%09c-1.6-0.3-2.7-0.6-4-0.4L37.7%2C1c-0.6-0.9-1.4-1.1-2.3-0.9L20.1%2C5c-0.5%2C0.3-1%2C1-0.9%2C2.3l1.8%2C10.6c-2%2C1.6-3.6%2C3.7-5.3%2C5.8L5.3%2C23%0A%09c-0.8-0.2-1.7%2C0.4-2%2C1.6L0%2C40.2c-0.2%2C0.8%2C0.4%2C2.1%2C1.1%2C2.3l9.8%2C3.7c0.7%2C2.6%2C1.4%2C5.2%2C2.5%2C7.5l-6%2C8.9c-0.6%2C0.7-0.4%2C2%2C0.3%2C2.5l12%2C10.7%0A%09c0.7%2C0.5%2C1.9%2C0.8%2C2.4%2C0.1L30.3%2C68.2z%20M26.7%2C37.3c1.6-7.4%2C9.1-12.3%2C16.5-10.8S55.6%2C35.7%2C54%2C43.1c-1.6%2C7.4-9.1%2C12.3-16.5%2C10.7%0A%09C30.1%2C52.3%2C25.1%2C44.7%2C26.7%2C37.3L26.7%2C37.3z%22%2F%3E%0A%3C%2Fsvg%3E');
  width:80px;
  height:80px;
  background-size:100% 100%;
  background-repeat:no-repeat;
  margin-top:-10px;
  margin-right:8px;
}

.two_main {
  background: url('data:image/svg+xml,%3Csvg%20version%3D%221.1%22%0A%09%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20xmlns%3Aa%3D%22http%3A%2F%2Fns.adobe.com%2FAdobeSVGViewerExtensions%2F3.0%2F%22%0A%09%20x%3D%220px%22%20y%3D%220px%22%20width%3D%22103px%22%20height%3D%22103.7px%22%20viewBox%3D%220%200%20103%20103.7%22%0A%09%20style%3D%22overflow%3Ascroll%3Benable-background%3Anew%200%200%20103%20103.7%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cstyle%20type%3D%22text%2Fcss%22%3E%0A%09.st0%7Bfill%3A%23F6921E%3B%7D%0A%3C%2Fstyle%3E%0A%3Cdefs%3E%0A%3C%2Fdefs%3E%0A%3Cpath%20class%3D%22st0%22%20d%3D%22M87.3%2C64.8c0.3-1.5%2C1.1-2.9%2C1.6-4.9c0.4-2%2C0.7-3.5%2C0.5-5.1l12.3-6.3c1.2-0.8%2C1.4-1.8%2C1.1-2.9l-6.3-19.6%0A%09c-0.4-0.6-1.3-1.3-2.9-1.1l-13.5%2C2.3c-2.1-2.5-4.7-4.7-7.4-6.8l0.8-13.4C74.3%2C5.8%2C73%2C4.5%2C72%2C4.3L52.1%2C0c-1-0.2-2.7%2C0.5-2.9%2C1.5%0A%09l-4.8%2C13c-3.2%2C0.4-6.1%2C1.8-9.5%2C3.2l-10.9-7.5c-1.4-0.8-2.5-0.5-3.7%2C0.3L6.5%2C25.8c-0.6%2C0.4-0.4%2C2%2C0.4%2C3.2l8.8%2C10.2%0A%09c-0.3%2C1.5-1.1%2C2.9-1.5%2C4.9c-0.4%2C2-0.7%2C3.5-0.6%2C5.1L1.2%2C55.4c-1.2%2C0.8-1.4%2C1.8-1.1%2C2.9l6.3%2C19.6c0.4%2C0.6%2C1.3%2C1.3%2C2.9%2C1.1l13.5-2.3%0A%09c2.1%2C2.5%2C4.7%2C4.7%2C7.4%2C6.8l-0.8%2C13.4c-0.2%2C1%2C0.6%2C2.2%2C2.1%2C2.5l20%2C4.2c1%2C0.2%2C2.7-0.5%2C2.9-1.5l4.7-12.6c3.3-0.9%2C6.6-1.7%2C9.5-3.2L80.1%2C94%0A%09c0.9%2C0.7%2C2.5%2C0.5%2C3.2-0.4L97%2C78.3c0.7-0.9%2C1-2.4%2C0.1-3.1L87.3%2C64.8z%20M47.8%2C69.5C38.3%2C67.5%2C32%2C57.8%2C34%2C48.3%0A%09c2-9.5%2C11.7-15.8%2C21.2-13.8c9.5%2C2%2C15.7%2C11.7%2C13.7%2C21.2C66.9%2C65.2%2C57.3%2C71.5%2C47.8%2C69.5L47.8%2C69.5z%22%2F%3E%0A%3C%2Fsvg%3E');
  width:100px;
  height:100px;
  background-size:100% 100%;
  background-repeat:no-repeat;
}

/* pap */
/*
.two {
  background: url('data:image/svg+xml,%3Csvg%20version%3D%221.1%22%0A%09%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20xmlns%3Aa%3D%22http%3A%2F%2Fns.adobe.com%2FAdobeSVGViewerExtensions%2F3.0%2F%22%0A%09%20x%3D%220px%22%20y%3D%220px%22%20width%3D%22103px%22%20height%3D%22103.7px%22%20viewBox%3D%220%200%20103%20103.7%22%0A%09%20style%3D%22overflow%3Ascroll%3Benable-background%3Anew%200%200%20103%20103.7%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cstyle%20type%3D%22text%2Fcss%22%3E%0A%09.st0%7Bfill%3A%235197d6%3B%7D%0A%3C%2Fstyle%3E%0A%3Cdefs%3E%0A%3C%2Fdefs%3E%0A%3Cpath%20class%3D%22st0%22%20d%3D%22M87.3%2C64.8c0.3-1.5%2C1.1-2.9%2C1.6-4.9c0.4-2%2C0.7-3.5%2C0.5-5.1l12.3-6.3c1.2-0.8%2C1.4-1.8%2C1.1-2.9l-6.3-19.6%0A%09c-0.4-0.6-1.3-1.3-2.9-1.1l-13.5%2C2.3c-2.1-2.5-4.7-4.7-7.4-6.8l0.8-13.4C74.3%2C5.8%2C73%2C4.5%2C72%2C4.3L52.1%2C0c-1-0.2-2.7%2C0.5-2.9%2C1.5%0A%09l-4.8%2C13c-3.2%2C0.4-6.1%2C1.8-9.5%2C3.2l-10.9-7.5c-1.4-0.8-2.5-0.5-3.7%2C0.3L6.5%2C25.8c-0.6%2C0.4-0.4%2C2%2C0.4%2C3.2l8.8%2C10.2%0A%09c-0.3%2C1.5-1.1%2C2.9-1.5%2C4.9c-0.4%2C2-0.7%2C3.5-0.6%2C5.1L1.2%2C55.4c-1.2%2C0.8-1.4%2C1.8-1.1%2C2.9l6.3%2C19.6c0.4%2C0.6%2C1.3%2C1.3%2C2.9%2C1.1l13.5-2.3%0A%09c2.1%2C2.5%2C4.7%2C4.7%2C7.4%2C6.8l-0.8%2C13.4c-0.2%2C1%2C0.6%2C2.2%2C2.1%2C2.5l20%2C4.2c1%2C0.2%2C2.7-0.5%2C2.9-1.5l4.7-12.6c3.3-0.9%2C6.6-1.7%2C9.5-3.2L80.1%2C94%0A%09c0.9%2C0.7%2C2.5%2C0.5%2C3.2-0.4L97%2C78.3c0.7-0.9%2C1-2.4%2C0.1-3.1L87.3%2C64.8z%20M47.8%2C69.5C38.3%2C67.5%2C32%2C57.8%2C34%2C48.3%0A%09c2-9.5%2C11.7-15.8%2C21.2-13.8c9.5%2C2%2C15.7%2C11.7%2C13.7%2C21.2C66.9%2C65.2%2C57.3%2C71.5%2C47.8%2C69.5L47.8%2C69.5z%22%2F%3E%0A%3C%2Fsvg%3E');
  width:100px;
  height:100px;
  background-size:100% 100%;
  background-repeat:no-repeat;
}
*/
/* pap */

.three_main {
  background: url('data:image/svg+xml,%3Csvg%20version%3D%221.1%22%0A%09%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20xmlns%3Aa%3D%22http%3A%2F%2Fns.adobe.com%2FAdobeSVGViewerExtensions%2F3.0%2F%22%0A%09%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2281px%22%20height%3D%2280.5px%22%20viewBox%3D%220%200%2081%2080.5%22%20style%3D%22overflow%3Ascroll%3Benable-background%3Anew%200%200%2081%2080.5%3B%22%0A%09%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cstyle%20type%3D%22text%2Fcss%22%3E%0A%09.st0%7Bfill%3A%23383838%3B%7D%0A%3C%2Fstyle%3E%0A%3Cdefs%3E%0A%3C%2Fdefs%3E%0A%3Cpath%20class%3D%22st0%22%20d%3D%22M30.3%2C68.2c1.2%2C0.2%2C2.3%2C0.9%2C3.8%2C1.2c1.6%2C0.3%2C2.7%2C0.6%2C4%2C0.4l4.9%2C9.6c0.6%2C0.9%2C1.4%2C1.1%2C2.3%2C0.9l15.3-4.9%0A%09c0.5-0.3%2C1-1%2C0.9-2.3l-1.8-10.6c2-1.6%2C3.6-3.7%2C5.3-5.8l10.5%2C0.6c1.1%2C0.6%2C2.1-0.4%2C2.3-1.1L81%2C40.7c0.2-0.8-0.4-2.1-1.1-2.3l-10.2-3.8%0A%09c-0.3-2.5-1.4-4.8-2.5-7.5l5.9-8.5c0.6-1.1%2C0.4-1.9-0.2-2.9l-12-10.7c-0.3-0.5-1.6-0.3-2.5%2C0.3l-8%2C6.9c-1.2-0.2-2.3-0.9-3.8-1.2%0A%09c-1.6-0.3-2.7-0.6-4-0.4L37.7%2C1c-0.6-0.9-1.4-1.1-2.3-0.9L20.1%2C5c-0.5%2C0.3-1%2C1-0.9%2C2.3l1.8%2C10.6c-2%2C1.6-3.6%2C3.7-5.3%2C5.8L5.3%2C23%0A%09c-0.8-0.2-1.7%2C0.4-2%2C1.6L0%2C40.2c-0.2%2C0.8%2C0.4%2C2.1%2C1.1%2C2.3l9.8%2C3.7c0.7%2C2.6%2C1.4%2C5.2%2C2.5%2C7.5l-6%2C8.9c-0.6%2C0.7-0.4%2C2%2C0.3%2C2.5l12%2C10.7%0A%09c0.7%2C0.5%2C1.9%2C0.8%2C2.4%2C0.1L30.3%2C68.2z%20M26.7%2C37.3c1.6-7.4%2C9.1-12.3%2C16.5-10.8S55.6%2C35.7%2C54%2C43.1c-1.6%2C7.4-9.1%2C12.3-16.5%2C10.7%0A%09C30.1%2C52.3%2C25.1%2C44.7%2C26.7%2C37.3L26.7%2C37.3z%22%2F%3E%0A%3C%2Fsvg%3E');
  width:80px;
  height:80px;
  background-size:100% 100%;
  background-repeat:no-repeat;
  margin-top:-50px;
  margin-left:-10px;
}

@keyframes spin-one {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-359deg) ;
    transform: rotate(-359deg) ;
  }
}

.spin-one {
  -webkit-animation: spin-one 1.5s infinite linear;
  animation: spin-one 1.5s infinite linear;
}

@keyframes spin-two {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(359deg);
  }
}

.spin-two {
  -webkit-animation: spin-two 2s infinite linear;
  animation: spin-two 2s infinite linear;
}

.transition-height_filter {
  transition: max-height 0.3s ease-in-out;
}


/* Add this CSS to your stylesheet */
@keyframes jiggle {
  0% { transform: translate(0, 0) rotate(0deg); }
  10% { transform: translate(-5px, -2px) rotate(-2deg); }
  20% { transform: translate(5px, 2px) rotate(2deg); }
  30% { transform: translate(-5px, -2px) rotate(-2deg); }
  40% { transform: translate(5px, 2px) rotate(2deg); }
  50% { transform: translate(-5px, -2px) rotate(-2deg); }
  60% { transform: translate(5px, 2px) rotate(2deg); }
  70% { transform: translate(-5px, -2px) rotate(-2deg); }
  80% { transform: translate(5px, 2px) rotate(2deg); }
  90% { transform: translate(-5px, -2px) rotate(-2deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
}

.animate-jiggle {
  animation: jiggle 0.6s ease-in-out;
}

/* customStyles.scss */
/* customStyles.module.scss */

/* Sidebar Styles */
.custom-sidebar {
  background-color: rgb(176, 19, 16); /* gray-800 */
  min-height: 100vh;
  width: 14rem; /* 224px */
  display: flex;
  flex-direction: column;
}

.custom-sidebar-header {
  background-color: #111827; /* gray-900 */
  display: flex;
  align-items: center;
  height: 4rem; /* 64px */
  padding: 0 1rem;
}

.custom-sidebar-header-link {
  color: #ffffff;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.custom-sidebar-header-link svg {
  width: 1.5rem; /* 24px */
  height: 1.5rem; /* 24px */
  margin-right: 0.5rem;
}

/* Navigation Styles */
.custom-nav {
  flex: 1;
  padding: 1rem 0.5rem;
}

.custom-nav-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.375rem;
  color: #d1d5db; /* gray-300 */
  text-decoration: none;
}

.custom-nav-item:hover {
  background-color: #374151; /* gray-700 */
  color: #ffffff;
}

.custom-nav-item-active {
  background-color: #111827; /* gray-900 */
  color: #ffffff;
  font-weight: 700;
}

.custom-nav-item-icon {
  width: 1.25rem; /* 20px */
  height: 1.25rem; /* 20px */
  margin-right: 0.75rem; /* 12px */
}

.custom-nav-item-text {
  font-size: 0.875rem; /* 14px */
}

/* Header Styles */
.custom-header {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  height: 4rem; /* 64px */
  padding: 0 1rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.custom-mobile-menu-button {
  padding: 0.5rem;
  border-right: 1px solid #e5e7eb; /* gray-200 */
  color: #6b7280; /* gray-500 */
}

// /* Tailwind: Hide on larger screens, show only on mobile */
// @media (max-width: 639px) {
//   .custom-mobile-menu-button {
//     display: block; /* Show the button on small screens */
//   }
// }

// /* Hide the button on medium and larger screens */
// @media (min-width: 640px) {
//   .custom-mobile-menu-button {
//     display: none;
//   }
// }

.custom-mobile-menu-button:focus {
  outline: 2px solid #6366f1; /* indigo-500 */
  outline-offset: 2px;
}

/* Main Content Styles */
.custom-main-content {
  padding: 1rem;
  overflow: auto;
  flex: 1;
 /* gray-50 */
}



.custom-button_main {
  display: inline-flex; /* Aligns items inline */
  align-items: center; /* Vertically centers content */
  justify-content: center; /* Horizontally centers content */
  border-radius: 0.375rem; /* Rounded corners */
  padding: 0.5rem 1rem; /* Tailwind's py-2 px-4 equivalent */
  font-size: 0.875rem; /* Tailwind's text-sm equivalent */
  font-weight: 500; /* Tailwind's font-medium equivalent */
  background-color: $primary-color;
  color: $white;
  border: 1px solid transparent; /* Transparent border */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* Tailwind's shadow-sm equivalent */
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    background-color: $primary-color-2;
  }

  &:focus {
    outline: none; /* Remove default focus outline */
    box-shadow: 0 0 0 2px $primary-color-2, 0 0 0 4px $primary-color-2-darker;
  }

  &:active {
    background-color: $primary-color-2-darker;
  }

  &:disabled {
    background-color: $primary_color;
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.custom-button-tree {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  background: linear-gradient(90deg, lighten($second_color, 10%), darken($second_color, 10%)); /* Dégradé horizontal */
  color: $text-color;
  border: 1px solid $border_color;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;

  &:hover {
    background: linear-gradient(90deg, lighten($second_color, 20%), darken($second_color, 20%)); /* Accentue le dégradé au survol */
    color: $primary-color-2;
    border-color: $primary-color-2;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $border_color, 0 0 0 4px darken($second_color, 10%);
  }

  &:active {
    background: linear-gradient(90deg, darken($second_color, 15%), darken($second_color, 30%)); /* Dégradé plus sombre */
    color: lighten($text-color, 20%);
    border-color: darken($primary-color-2, 15%);
  }

  &:disabled {
    background: linear-gradient(90deg, lighten($second_color, 40%), lighten($second_color, 50%));
    color: lighten($text-color, 30%);
    cursor: not-allowed;
    opacity: 0.6;
  }
}
.custom-button-secondary {
  display: inline-flex; /* Aligns items inline */
  align-items: center; /* Vertically centers content */
  justify-content: center; /* Horizontally centers content */
  border-radius: 0.375rem; /* Rounded corners */
  padding: 0.5rem 1rem; /* Tailwind's py-2 px-4 equivalent */
  font-size: 0.875rem; /* Tailwind's text-sm equivalent */
  font-weight: 500; /* Tailwind's font-medium equivalent */
  background-color: $second_color;
  color: $text-color;
  border: 1px solid $border_color;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* Tailwind's shadow-sm equivalent */
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease,
    box-shadow 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: $secondary_color_darker;
    color: $primary-color-2; /* Darker text color on hover */
    border-color: $primary-color-2; /* Match hover border to darker color */
  }

  &:focus {
    outline: none; /* Remove default focus outline */
    box-shadow: 0 0 0 2px $border_color, 0 0 0 4px $secondary_color_darker;
  }

  &:active {
    background-color: $border_color; /* Match border color when active */
    color: $second_color; /* Invert text and background color */
    border-color: $primary_color_2_darker;
  }

  &:disabled {
    background-color: lighten($second_color, 40%);
    color: lighten($text_color, 30%);
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.custom-button-yellow {
  display: inline-flex; /* Aligns items inline */
  align-items: center; /* Vertically centers content */
  justify-content: center; /* Horizontally centers content */
  border-radius: 0.375rem; /* Rounded corners */
  padding: 0.5rem 1rem; /* Equivalent to Tailwind's py-2 px-4 */
  font-size: 0.875rem; /* Tailwind's text-sm equivalent */
  font-weight: 600; /* Medium weight */
  background-color: $yellow-color; /* Yellow base color */
  color: #ffffff; /* White text */
  border: 1px solid $yellow-color-darker; /* Slightly darker border */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease,
    box-shadow 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: $yellow-color-lighter; /* Lighter shade on hover */
    color: #000000; /* Black text for better contrast */
    border-color: $yellow-color; /* Border matches base color */
  }

  &:focus {
    outline: none; /* Remove default focus outline */
    box-shadow: 0 0 0 2px $yellow-color, 0 0 0 4px $yellow-color-lighter; /* Glow effect */
  }

  &:active {
    background-color: $yellow-color-darker; /* Darker shade on active */
    color: #ffffff; /* White text */
    border-color: $yellow-color-darker;
  }

  &:disabled {
    background-color: lighten($yellow-color, 40%);
    color: lighten(#ffffff, 30%);
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.custom-button-gray {
  display: inline-flex; /* Aligns items inline */
  align-items: center; /* Vertically centers content */
  justify-content: center; /* Horizontally centers content */
  border-radius: 0.375rem; /* Rounded corners */
  padding: 0.5rem 1rem; /* Equivalent to Tailwind's py-2 px-4 */
  font-size: 0.875rem; /* Tailwind's text-sm equivalent */
  font-weight: 600; /* Medium weight */
  background-color: $gray-color-lighter; /* Gray base color */
  color: black; /* White text */
  border: 1px solid $gray-color-darker; /* Slightly darker border */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease,
    box-shadow 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: $gray-color-darker; /* Lighter shade on hover */
    color: white; /* Black text for better contrast */
    border-color: $gray-color; /* Border matches base color */
  }

  &:focus {
    outline: none; /* Remove default focus outline */
    box-shadow: 0 0 0 2px $gray-color, 0 0 0 4px $gray-color-lighter; /* Glow effect */
  }

  &:active {
    background-color: $gray-color-darker; /* Darker shade on active */
    color: #ffffff; /* White text */
    border-color: $gray-color-darker;
  }

  &:disabled {
    background-color: lighten($gray-color, 40%);
    color: lighten(#ffffff, 30%);
    cursor: not-allowed;
    opacity: 0.6;
  }
}
.custom-button-green {
  display: inline-flex; /* Aligns items inline */
  align-items: center; /* Vertically centers content */
  justify-content: center; /* Horizontally centers content */
  border-radius: 0.375rem; /* Rounded corners */
  padding: 0.5rem 1rem; /* Tailwind's py-2 px-4 equivalent */
  font-size: 0.875rem; /* Tailwind's text-sm equivalent */
  font-weight: 500; /* Medium weight */
  background-color: #25D366; /* WhatsApp green */
  color: white; /* White text for contrast */
  border: 1px solid darken(#25D366, 10%); /* Slightly darker green border */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease,
    box-shadow 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: darken(#25D366, 10%); /* Darker green on hover */
    color: white; /* Maintain white text for better visibility */
    border-color: darken(#25D366, 15%); /* Darker border on hover */
  }

  &:focus {
    outline: none; /* Remove default focus outline */
    box-shadow: 0 0 0 2px darken(#25D366, 15%), 0 0 0 4px lighten(#25D366, 10%); /* Focus glow effect */
  }

  &:active {
    background-color: darken(#25D366, 20%); /* Much darker green on active */
    border-color: darken(#25D366, 25%); /* Very dark green border */
  }

  &:disabled {
    background-color: lighten(#25D366, 40%); /* Lightened green for disabled state */
    color: lighten(white, 30%); /* Lightened text color */
    cursor: not-allowed;
    opacity: 0.6;
  }
}



/* Label Styling */
.custom-label_ {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: black; /* Third color */
  margin-bottom: 8px;
}

.custom-label_ span {
  font-weight: normal;
  font-size: 14px;
  margin-left: 8px;
  color: $primary_color_2_darker; /* Primary color 2 darker */
}

/* Input Styling */
.custom-input_ {
  width: 100%;
  padding: 10px;
  border: 1px solid $primary-color; /* Border color */
  border-radius: 8px;
  font-size: 16px;
  color: $third_color; /* Text color */
 /* Light background */
  outline: none;
  transition: $primary-color 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.custom-input_:focus {
  border-color: $primary-color; /* Darker border color */
  box-shadow: 0 0 4px $primary-color; /* Focus glow */
  background-color: $second_color_lighter; /* Slightly darker background */
}

.custom-input_::placeholder {
  color: lighten($third_color, 20%); /* Light gray for placeholders */
  font-style: italic;
}

/* Title Styling */
.page-title {
  font-family: "Open Sans", sans-serif; /* Elegant font */
  font-size: 36px; /* Large font size */
  font-weight: bold; /* Strong emphasis */
  color: $primary-color-2; /* Use primary color 2 for the text */
  text-align: center; /* Center-align the title */
  margin-bottom: 24px; /* Space below the title */
  position: relative; /* Required for pseudo-elements */

  /* Decorative underline */
  &::after {
    content: "";
    position: absolute;
    bottom: -8px; /* Below the text */
    left: 50%;
    transform: translateX(-50%);
    width: 80px; /* Line width */
    height: 4px; /* Line thickness */
    background: linear-gradient(
      to right,
      $primary-color-2,
      $primary-color-2-darker
    ); /* Gradient underline */
    border-radius: 4px; /* Rounded edges */
  }

  /* Glow effect on hover */
  &:hover {
    color: $primary-color-2-darker; /* Darker color on hover */
    transition: color 0.3s ease-in-out;

    &::after {
      background: $primary-color-2-darker; /* Match hover color */
      width: 120px; /* Increase line width */
      transition: width 0.3s ease-in-out, background-color 0.3s ease-in-out;
    }
  }
}


/* Upload Input Wrapper */
.upload-wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 250px; /* Adjust as needed */
  margin: 16px 0;

  .upload-button {
    background-color: $secondary_color_darker; /* Button base color */
    color: $primary_color_2_darker; /* Button text color */
    font-size: 14px;
    font-weight: bold;
    padding: 10px 16px;
    border: 1px solid $border_color; /* Match border to theme */
    border-radius: 8px; /* Rounded corners */
    cursor: pointer;
    text-align: center;
    display: block;
    transition: $secondary_color_darker 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      background-color: $border_color; /* Darker hover background */
      color: #ffffff; /* White text */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Subtle shadow effect */
    }

    &:active {
      background-color: $primary_color_2_darker; /* Active state background */
      box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2); /* Inset shadow */
    }
  }

  .upload-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0; /* Hide input */
    cursor: pointer;
  }

  .upload-preview {
    margin-top: 16px;
    display: block;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border: 2px solid $border_color; /* Border matches theme */
    border-radius: 8px; /* Rounded corners */
  }
}





.main-content {
  position: relative;
  z-index: 0;
}

.alert-success-custom {
  background-color: $second_color; // Light pink background
  border-color: $border_color; // Dark red border color
  color: $text_color; // Dark red text color
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;

  strong {
    color: $primary_color_2_darker; // Darker primary color for emphasis
  }

  a {
    color: $primary_color_2_darker; // Darker primary for links for consistency
    text-decoration: underline;
    &:hover {
      color: $secondary_color_darker; // A lighter red on hover
    }
  }

  &.fade.show {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
}
.alert-warning-custom {
  background-color: #ff7171; // Bright orange background
  border-color: darken(#ff7171, 15%); // Dark orange border color
  color: #ffffff; // White text color for readability
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  font-weight: 600;

  strong {
    color: darken(#ff7171, 20%); // Even darker orange for emphasis
  }

  a {
    color: darken(#ff7171, 20%); // Consistent dark orange for links
    text-decoration: underline;
    &:hover {
      color: lighten(#ff7171, 10%); // Lighter orange on hover
    }
  }

  &.fade.show {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
}

.alert-success-custom_green {
  background-color: #4CAF50; // Vibrant green background
  border-color: darken(#4CAF50, 15%); // Dark green border color
  color: #ffffff; // White text color for high contrast
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;

  strong {
    color: darken(#4CAF50, 20%); // Even darker green for emphasis
  }

  a {
    color: darken(#4CAF50, 20%); // Consistent dark green for links
    text-decoration: underline;
    &:hover {
      color: lighten(#4CAF50, 10%); // Lighter green on hover
    }
  }

  &.fade.show {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
}


.filter-header {
  cursor: pointer;
  user-select: none; // Prevent text selection on click

  svg {
    transition: transform 0.3s ease;
  }
}

.filter-container {
  transition: max-height 0.5s ease, opacity 0.5s ease;
 
  background: #fff;
  border-radius: 0px 0px 10px 10px ;
  border: 1px solid rgb(226, 232, 240);
  border-top: none;

  &.collapsed {
    max-height: 0;
    opacity: 0;
    display: none;
  }

  &.expanded {
    max-height: 100vh; // Adjust based on your content's possible maximum height
    opacity: 1;
  }
}
/* SASS for Hover Effects and Animations */

/* Zoom effect for product images */
.product-image {
  transition: transform 0.3s ease-in-out;
}

.product-wrapper:hover .product-image {
  transform: scale(1.05); /* Zoom effect */
}

/* Sale tag animation */
.sale-tag {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ff7171; /* Sale tag red */
  color: white;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  font-weight: bold;
  border-radius: 0 0 0 0.375rem;
  animation: slideIn 0.5s ease-out forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Smooth animation for floating button */
.flying-button-wrapper {
  position: absolute;
  bottom: 10px;
  right: 10px;
  transform: translate(0, 0);
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -10px);
  }
  100% {
    transform: translate(0, 0);
  }
}

// Define keyframes
@keyframes pulseGlow {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 113, 113, 0.7);
  }
  70% {
    box-shadow: 0 0 20px 10px rgba(255, 113, 113, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 113, 113, 0);
  }
}

@keyframes bounceScale {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes colorFade {
  0% {
    color: #d4243a;
  }
  100% {
    color: #ff7171;
  }
}

// Panel Updated Animation
.panel-updated {
  animation: pulseGlow 0.8s ease-in-out;

  .icon-wrapper {
    animation: bounceScale 0.8s ease-in-out;
  }

  .icon {
    animation: colorFade 0.8s ease-in-out;
    color: #ff7171;
  }

  .item-count {
    animation: bounceScale 0.8s ease-in-out, colorFade 0.8s ease-in-out;
    color: #ff7171;
    font-weight: bold;
  }
}

// Panel Container
.panel-container {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  border-radius: 8px;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-3px);
  }

  .group {
    display: flex;
    align-items: center;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: rgba(255, 113, 113, 0.1);
    padding: 0.5rem;
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
    color: #d4243a;
    transition: color 0.3s ease-in-out;
  }

  .item-count {
    margin-left: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
    color: #333;
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
}


// Keyframes for the animations
@keyframes pulseGlow {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 113, 113, 0.8);
  }
  50% {
    box-shadow: 0 0 25px 10px rgba(255, 113, 113, 0.3);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 113, 113, 0);
  }
}

@keyframes scaleBounce {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes wiggle {
  0%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
}

// Main animation class for button update
.button-updated {
  animation: scaleBounce 0.7s ease-out, pulseGlow 1s ease-out;

  .shopping-icon {
    animation: wiggle 0.7s ease-out;
    color: #ff7171; // Highlight color for icon
    transition: color 0.3s ease-in-out;
  }

  .panel-count {
    animation: scaleBounce 0.7s ease-out;
    color: #d4243a; // Highlight color for text
    transition: color 0.3s ease-in-out;
  }
}



/* Global Styles for the Modal */
.welcome-modal {
  font-family: 'Poppins', sans-serif;
  width: 600px;
  border-radius: 15px;
  background-color: #f5f5f5;  // Light gray background
  padding: 30px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  animation: fadeIn 0.6s ease-out;

  .modal-header {
    background-color: #008080;   // Vibrant teal header
    color: #ffffff;               // White text
    padding: 20px;
    border-radius: 15px 15px 0 0;
    text-align: center;

    h2 {
      font-size: 2rem;
      font-weight: 600;
      margin: 0;
      letter-spacing: 1px;
    }
  }

  .modal-body {
    background-color: #ffffff;  // White background for the body
    padding: 20px;
    border-radius: 0 0 15px 15px;
    box-shadow: inset 0 4px 15px rgba(0, 0, 0, 0.1);
    text-align: center;

    p {
      font-size: 1.2rem;
      color: #333333;
      line-height: 1.6;
      margin-top: 20px;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: center;
    padding: 15px;
    background-color: transparent;
  }

  .start-tour-btn {
    background-color: #008080;  // Vibrant teal button
    color: #ffffff;              // White text
    border: none;
    padding: 15px 40px;
    font-size: 1rem;
    border-radius: 30px;
    cursor: pointer;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease;
    text-transform: uppercase;
    font-weight: 500;
    
    &:hover {
      background-color: #006666; // Darker teal when hovered
      transform: scale(1.05);    // Slight scale-up effect
    }

    &:focus {
      outline: none;
    }
  }
}

/* Keyframe Animation for Fade-In */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.sticky-notif-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
  /* This container must be tall enough (or have content) 
     so that scrolling occurs in the first place */
  // margin-bottom: 2rem; /* just some spacing after the notif bar */
}

.sticky-notif {
  position: sticky;
  top: 0;
  
  border-radius: 10px;
  /* You need a background color (or gradient) so it’s visible when it sticks */
  background: linear-gradient(45deg, #d4243a, #9a3743);
  color: #fff;
  padding: 1rem 1.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 6px rgba(0,0,0,0.15);
}

.sticky-notif-btn {
  background: #ff7171;
  border: none;
  color: #fff;
  padding: 0.6rem 1.2rem;
  font-size: 0.9rem;
  font-weight: 700;
  cursor: pointer;
  border-radius: 4px;
  transition: background 0.3s ease, transform 0.3s ease;
}

.sticky-notif-btn:hover {
  background: #d4243a;
  transform: scale(1.03);
}
// Container
.aside-container {
  background: linear-gradient(to bottom right, #f7f9fc, #ffffff);
  padding: 1rem;
  border-radius: 6px;
  animation: fadeInDown 0.5s ease-in-out;

  // Optional CSS animation 
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}

// -------------------------------
// Tab content container
.tabcontent-fluid {
  // make the content more fluid
  padding: 0.5rem;
}

// -------------------------------
// Header Title
.list-group-title {
  background-color: $primary-color;
  color: $second_color !important;
  border: none;
  margin-bottom: 1rem;
  border-radius: 4px;
  padding: 0.75rem 0;
  letter-spacing: 1px;
  font-size: 0.9rem;
}

// -------------------------------
// Accordion Styles
.custom-accordion {
  margin-bottom: 1.5rem;
  border: 1px solid #eee;
  border-radius: 4px;
  overflow: hidden;
  background-color: #fff;

  .accordion-header {
    cursor: pointer;
    position: relative;
    padding: 1rem;
    background-color: #f9fafc;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #eef0f5;
    }

    &.open {
      background-color: #e6e8ec;
    }

    .accordion-title {
      flex: 1;
      font-weight: 600;
      color: $text-color;
    }

    .accordion-icon {
      transition: transform 0.3s;
      &.rotate {
        transform: rotate(180deg);
      }
    }
  }

  .accordion-body {
    &.collapsed {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-out;
    }

    &.expanded {
      max-height: 700px; 
      overflow: scroll;
      // max-height: 500px; // set a max big enough to hold content
      transition: max-height 0.3s ease-in;
    }

    .accordion-content {
      padding: 1rem;
      background-color: #fff;
    }
  }
}

// -------------------------------
// Individual caisse item
.item-caisse {
  position: relative;
  margin-bottom: 0.5rem;
  padding: 1rem;
  border: 1px solid #ddd;
  transition: background-color 0.3s;
  background-color: #fff;
  cursor: pointer;
  border-radius: 4px;


  &:hover {
    background-color: #f2f7ff;
  }

  &.active-caisse {
    background-color: $primary-color;
    color: $second_color;

    // Icon changes color, if needed
    i {
      color: white;
    }
  }

  .caisse-heading {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0.25rem;
  }

  .caisse-details {
    small {
      font-size: 0.8rem;
      i {
        color: $primary-color;
      }
    }
    .mr-3 {
      margin-right: 1rem;
    }
  }
}


.footer {
  background: linear-gradient(to right, $second_color, $primary-color); /* Gradient from indigo to blue */
  color: $primary_color_2; /* Set a light color for the footer text */
  font-weight: 600;
  padding: 10px 0;
 /* Changed to relative to avoid overlap */
  bottom: 0;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
  z-index: 10;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }

  .left {
    display: flex;
    align-items: center;

    span {
      font-size: 1.25rem;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 2px;
    }
  }

  .center {
    text-align: center;

    p {
      font-size: 0.875rem;
    }
  }

  .right {
    display: flex;
    gap: 15px;

    a {
      color: #d1d1d1;
      font-size: 1.25rem;
      transition: color 0.3s ease, transform 0.3s ease;

      &:hover {
        color: white;
        transform: scale(1.1);
      }
    }
  }
}

.special-container {
  background-color: #f9f9f9; /* Light background for the container */
  border-radius: 12px; /* Rounded corners for the container */
  padding: 20px; /* Padding around the content */
  margin: 20px auto; /* Center the container and add some space around it */
  max-width: 1200px; /* Limit the container width */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Light shadow for isolation effect */
  position: relative; /* For absolute positioning of elements inside */
  
  .form-group {
    margin-bottom: 20px;
  }

  label {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-bottom: 8px;
  }

  .invalid-feedback {
    font-size: 12px;
    color: #ff4d4f;
  }

  .popover-header {
    background-color: #007bff;
    color: white;
    font-size: 14px;
    font-weight: 600;
  }

  .popover-body {
    font-size: 12px;
    color: #333;
  }

  .button-info {
    background-color: #28a745;
    border-radius: 8px;
    padding: 6px 12px;
    transition: background-color 0.3s ease;
    i {
      color: white;
    }

    &:hover {
      background-color: #218838;
    }
  }
  
  .custom-input {
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #ddd;
    width: 100%;
  }

  .select-container {
    margin-top: 10px;
    .react-select__control {
      border-radius: 8px;
      border: 1px solid #ddd;
    }
  }
}
.alert-container-extended-v2 {
  background-color: #fafafa; /* Slightly darker light gray background */
  border-radius: 12px; /* Rounded corners */
  padding: 30px; /* More spacious padding */
  margin: 20px auto; /* Center the container with space around it */
  max-width: 1200px; /* Limit the width for responsiveness */
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15); /* Deeper shadow for stronger isolation */
  position: relative; /* For absolute positioning of internal elements */
  
  .form-group {
    margin-bottom: 30px; /* Increased bottom margin for spacing */
  }

  label {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 12px; /* More space between label and input */
  }

  .invalid-feedback {
    font-size: 12px;
    color: #ff4d4f;
  }

  .popover-header {
    background-color: #007bff;
    color: white;
    font-size: 14px;
    font-weight: bold;
  }

  .popover-body {
    font-size: 12px;
    color: #333;
  }

  .button-info {
    background-color: $primary_color;
    border-radius: 8px;
    padding: 8px 12px;
    transition: background-color 0.3s ease;
    i {
      color: white;
    }

    &:hover {
      background-color: $primary_color_2;
    }
  }

  .custom-input {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ddd;
    width: 100%;
    
  }
  }

  .custom-button {
    background-color: $primary_color;
    color: white;
    border-radius: 50%;
    padding: 10px;
    transition: background-color 0.3s ease;
    svg {
      width: 22px;
      height: 22px;
    }

    &:hover {
      background-color: $primary_color_2;
    }
  }

  .sales-reports {
    padding: 20px;
    @media screen and (max-width: 768px) {  // md screen and above
     padding:0;
    }
  
    /* Container for all cards */
    .cards-container {
      /* Grid layout for medium and large screens */
      @media screen and (min-width: 768px) {  // md screen and above
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 20px;
      }
  
      @media screen and (min-width: 1024px) {  // lg screen and above
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      }
  
      /* Mobile-specific styles */
      @media screen and (max-width: 767px) {  // sm screen
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
        
        .card {
          background-color: #fff;
          padding: 15px;  // Reduced padding for a more compact look
          border-radius: 12px;
          border: 1px solid #ff7171;  // Light border around the card
          box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
          text-align: center;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          margin-bottom: 10px;  // Space between cards
  
          &:hover {
            transform: translateY(-5px);
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
          }
  
          svg {
            width: 50px;
            height: 50px;
            margin-bottom: 15px;
          }
  
          p {
            font-size: 18px;
            font-weight: bold;
            color: #333;
            margin-bottom: 10px;
          }
  
          small {
            font-size: 14px;
            color: #777;
          }
        }
      }
  
      /* For larger screen sizes (md, lg, etc.) */
      .card {
        background-color: #fff;
        padding: 20px;
        border-radius: 12px;
        text-align: center;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
  
        &:hover {
          transform: translateY(-10px);
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        }
  
        svg {
          width: 40px;
          height: 40px;
          margin-bottom: 15px;
        }
  
        p {
          font-size: 16px;
          font-weight: bold;
          color: #333;
          margin-bottom: 10px;
        }
  
        small {
          font-size: 14px;
          color: #777;
        }
      }
    }
  
    /* Tabs styling */
    .tabs button {
      background-color: #fff;
      border: 2px solid #ccc;
      padding: 10px 20px;
      font-weight: bold;
      cursor: pointer;
    }
  
    .tabs button:hover {
      border-color: #ff7043;
      color: #ff7043;
    }
  
    .tabs button.active {
      background-color: #ff7043;
      color: white;
    }
  }

  .cardsWrapper {
   
    display: flex;
    flex-direction: column;
    gap: 1.5rem; // Similar to sm:gap-6
    padding: 0 1rem; // Similar to sm:px-4
  
    // Example: If you still need responsive breakpoints:
    // @media (min-width: 640px) {
    //   flex-direction: column;
    //   gap: 1.5rem;
    //   padding: 0 1rem;
    // }
  
    .cardItem {
      width: 100%;
      display: flex;
      // flex-direction: column;
      align-items: center; 
      border-radius: 0.5rem; // approx .rounded-lg
      padding: 1rem;         // .p-4
      border: 1px solid #d1d5db; // border-gray-300
      background-color: #fff;
      cursor: pointer;
      transition: all 0.3s ease; // .transition-all .duration-300
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); // .shadow-sm
  
      &:hover {
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // .hover:shadow-lg
      }
  
      .iconWrapper {
       margin-right:"10px";
       width:20%;
        
        
        border-radius: 9999px; // .rounded-full
        display: flex;
        align-items: center;
        justify-content: center;
      }
  
      .cardTitle {
        font-size:14px; // .text-lg
        text-align: center;  // .text-center
        font-weight: 700;    // .font-semibold
        color: #1f2937;      // .text-gray-800
        margin-bottom: 0.25rem;
      }
  
      .cardDesc {
        text-align: center;  // .text-center
        font-size:12px; // .text-sm
        color: #4b5563;      // .text-gray-600
      }
    }
  }

  .my-unique-select-container {
    // By default, show the container
    display: block;
  
    // At the "sm" breakpoint (640px in Tailwind), hide it
    @media (min-width: 640px) {
      display: none;
    }
  
    // Screen-reader-only label styles (similar to Tailwind's .sr-only)
    .my-unique-sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap; 
      border: 0;
    }
  
    // Your <select> element styles
    select {
      display: block;
      width: 100%;
      border: 1px solid #d1d5db;    // border-gray-300
      border-radius: 0.375rem;      // rounded-md
  
      // Imitate focus:ring-red-500 and focus:border-red-800
      &:focus {
        outline: none;
        /* 
          “Ring” in Tailwind typically uses a box-shadow for the glow;
          below is an approximation using red-500 (#ef4444) at 2px spread.
        */
        box-shadow: 0 0 0 2px rgba(#ef4444, 0.5);
        border-color: #7f1d1d; // roughly red-800
      }
    }
  }
  
  
  
  

 
  
  




